var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-text',[_c('h3',[_vm._v("What best describes how you intend to use this Wikibase?")]),_c('v-form',{ref:"inputForm"},[_c('v-radio-group',{attrs:{"rules":[() => !!_vm.value.purpose || 'Please select an option.']},model:{value:(_vm.value.purpose),callback:function ($$v) {_vm.$set(_vm.value, "purpose", $$v)},expression:"value.purpose"}},[_c('v-radio',{ref:"test",attrs:{"value":"data_hub"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("To "),_c('b',[_vm._v("publish potentially useful data")])])]},proxy:true}])}),_c('v-radio',{attrs:{"value":"data_lab"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("To refine, back up, or "),_c('b',[_vm._v("experiment with data in an isolated environment")])])]},proxy:true}])}),_c('v-radio',{attrs:{"value":"tool_lab"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("To build tools, write documentation, or "),_c('b',[_vm._v("contribute")]),_vm._v(" to the Wikidata & Wikibase ecosystem "),_c('b',[_vm._v("in ways other than data")])])]},proxy:true}])}),_c('v-radio',{attrs:{"value":"test_drive"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("To "),_c('b',[_vm._v("learn about the tool")]),_vm._v(", or "),_c('b',[_vm._v("evaluate")]),_vm._v(" whether it works for my use case")])]},proxy:true}])}),_c('v-radio',{attrs:{"value":"other"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Other: "),_c('v-text-field',{staticClass:"pl-1 mt-n1 mb-n2",attrs:{"counter":"200","dense":"","rules":[
              () => _vm.value.purpose !== 'other'
                || !! _vm.value.otherPurpose
                || 'Please provide a response.',

              () => _vm.value.purpose !== 'other'
                || !! (_vm.value.otherPurpose && _vm.value.otherPurpose.length < 201)
                || 'Text must be 200 characters or less.'
            ]},model:{value:(_vm.value.otherPurpose),callback:function ($$v) {_vm.$set(_vm.value, "otherPurpose", $$v)},expression:"value.otherPurpose"}})]},proxy:true}])}),_c('v-radio',{attrs:{"value":"decide_later"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" I will decide later ")]},proxy:true}])})],1),(_vm.value.purpose==='data_hub')?_c('div',{staticClass:"pt-3"},[_c('h3',[_vm._v("Who is the intended audience for this data?")]),_c('v-radio-group',{attrs:{"rules":[
        _vm.value.purpose !== 'data_hub'
        || !! _vm.value.audience
        || 'Please select an option.'
      ]},model:{value:(_vm.value.audience),callback:function ($$v) {_vm.$set(_vm.value, "audience", $$v)},expression:"value.audience"}},[_c('v-radio',{ref:"test",attrs:{"value":"wide"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Anyone interested ")]},proxy:true}],null,false,3364145486)}),_c('v-radio',{attrs:{"value":"narrow"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Myself or my organization ")]},proxy:true}],null,false,1630330369)}),_c('v-radio',{staticClass:"mt-n3",attrs:{"value":"other"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Other: "),_c('v-text-field',{staticClass:"pl-1",attrs:{"counter":"200","dense":"","rules":[
                () => _vm.value.purpose !== 'data_hub'
                  || _vm.value.audience !== 'other'
                  || !! _vm.value.otherAudience
                  || 'Please provide a response.',

                () => _vm.value.purpose !== 'data_hub'
                  || _vm.value.audience !== 'other'
                  || !! (_vm.value.otherAudience && _vm.value.otherAudience.length < 201)
                  || 'Text must be 200 characters or less.'
              ]},model:{value:(_vm.value.otherAudience),callback:function ($$v) {_vm.$set(_vm.value, "otherAudience", $$v)},expression:"value.otherAudience"}})]},proxy:true}],null,false,2819186667)})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"button","disabled":_vm.inFlight},on:{"click":function($event){return _vm.$emit('previous-step')}}},[_vm._v(" < PREVIOUS ")]),_c('v-btn',{attrs:{"type":"button","color":"primary","disabled":_vm.inFlight},on:{"click":_vm.nextStep}},[_vm._v(" Next > ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }