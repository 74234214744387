var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-text',[_c('v-form',{ref:"inputForm"},[_c('h3',[_vm._v("How long do you plan to use this Wikibase?")]),_c('v-radio-group',{attrs:{"error-messages":_vm.error,"rules":[() => !!_vm.value.temporality || 'Please select an option.']},model:{value:(_vm.value.temporality),callback:function ($$v) {_vm.$set(_vm.value, "temporality", $$v)},expression:"value.temporality"}},[_c('v-radio',{ref:"test",attrs:{"value":"permanent"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" I would prefer to keep it on a permanent basis ")]},proxy:true}])}),_c('v-radio',{attrs:{"value":"temporary"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" It is temporary/disposable. I will no longer need it after it served its purpose ")]},proxy:true}])}),_c('v-radio',{attrs:{"value":"other"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Other: "),_c('v-text-field',{staticClass:"pl-1 mt-n1 mb-n2",attrs:{"dense":"","counter":"200","rules":[
            () => _vm.value.temporality !== 'other'
              || !! _vm.value.otherTemporality
              || 'Please provide a response.',

            () => _vm.value.temporality !== 'other'
              || !! (_vm.value.otherTemporality && _vm.value.otherTemporality.length < 201)
              || 'Text must be 200 characters or less.'
          ]},model:{value:(_vm.value.otherTemporality),callback:function ($$v) {_vm.$set(_vm.value, "otherTemporality", $$v)},expression:"value.otherTemporality"}})]},proxy:true}])}),_c('v-radio',{attrs:{"value":"decide_later"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" I will decide later ")]},proxy:true}])})],1),_c('h3',{staticClass:"mt-6"},[_vm._v("Terms of Use")]),_c('v-checkbox',{attrs:{"disabled":_vm.inFlight,"rules":[() => !!_vm.value.terms || 'You must accept the Terms of Service.']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to the "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('a',_vm._g({attrs:{"target":"_blank","href":"/terms-of-use"},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" Terms of Use")])]}}])},[_vm._v(" Opens in new window ")]),_vm._v(". ")],1)]},proxy:true}]),model:{value:(_vm.value.terms),callback:function ($$v) {_vm.$set(_vm.value, "terms", $$v)},expression:"value.terms"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"type":"button","disabled":_vm.inFlight},on:{"click":_vm.previousStep}},[_vm._v(" < Previous ")]),_c('v-btn',{attrs:{"type":"button","color":"primary","disabled":_vm.inFlight},on:{"click":_vm.submitWholeForm}},[_vm._v(" Create Wiki ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }